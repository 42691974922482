import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilSpeedometer,
  cilCalendar,
  cilInput,
  cilMoney,
  cilList,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Billing",
  },
  {
    component: CNavItem,
    name: "Quick Sale",
    to: "/quicksale",
    icon: <CIcon icon={cilInput} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Appointment",
    to: "/appointment",
    icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Customers",
    to: "/customers",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },

  {
    component: CNavTitle,
    name: "Master",
  },
  {
    component: CNavGroup,
    name: "Offerings",
    to: "/offerings",
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Service",
        to: "/offerings/service",
      },
      {
        component: CNavItem,
        name: "Product",
        to: "/offerings/product",
      },
      {
        component: CNavItem,
        name: "Prepaid",
        to: "/offerings/prepaid",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Staffs",
    to: "/staffs",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Expense",
    to: "/expense",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Expense Category",
        to: "/expense/expense-category",
      },
      {
        component: CNavItem,
        name: "Expense List",
        to: "/expense/expense-list",
      },
    ],
  },
];

export default _nav;
