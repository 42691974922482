import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockLocked, cilPen } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import avatar8 from "../assets/images/avatars/dropdownLogo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

const AppHeaderDropdown = () => {
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const token = userdata ? userdata.data.token : null;
  const { data } = JSON.parse(sessionStorage.getItem("userdata"));
  const navigate = useNavigate();
  const logout = async () => {
    sessionStorage.removeItem("userdata");
    navigate("/", { replace: true });
  };

  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    axios
      .get(`${baseURL}/profile/getProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        // console.log(data);
        setProfileImage(
          data.profileImage ? filePathToUrl(data.profileImage) : avatar8
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function filePathToUrl(filePath) {
    const imageBaseUrl = baseURL;
    const imagePath = filePath
      .replace(/\\/g, "/")
      .split("/")
      .slice(1)
      .join("/");
    return `${imageBaseUrl}/static/${imagePath}`;
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar
          src={profileImage ? profileImage : avatar8}
          size="lg"
          shape="rounded-0"
        />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 pb-0" placement="bottom-end">
        <h6 className="pt-2 px-4 justify-content-center">
          Hi {data.salonName}
        </h6>
        <CDropdownDivider />
        <CDropdownItem
          className="pb-2"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/salon-profile")}
        >
          <CIcon icon={cilPen} className="me-2" />
          Salon Profile
        </CDropdownItem>
        <CDropdownItem href="*" onClick={() => logout()} className="pb-2">
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
