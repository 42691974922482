import React, { forwardRef, useEffect, useState } from "react";
import {
  CCardBody,
  CCardText,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import moment from "moment";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

const AppointmentPrint = forwardRef(
  (
    {
      billData,
      finalAmount,
      calculatedDiscount,
      totalTaxAmount,
      totalAmount,
      appointmentData,
      payMode,
    },
    ref
  ) => {
    const { data } = JSON.parse(sessionStorage.getItem("userdata"));
    const token = data.token;
    const [salonData, setSalonData] = useState({});
    useEffect(() => {
      axios
        .get(`${baseURL}/profile/getProfile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { data } = res.data;
          setSalonData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
    return (
      <div
        ref={ref}
        id="print-content"
        style={{
          top: "20px",
          padding: "20px",
          display: "none",
        }}
      >
        <CRow>
          <CCol sm={6}>
            <CCardBody>
              <CCardText>
                <strong>
                  {billData?.registers.firstName} {billData?.registers.lastName}
                </strong>
                <h6>(+91) {billData?.registers.mobile}</h6>
                {/* <h6>
                  Instagram Id : {billData?.registers.customers[0].instagramId}
                </h6>
                <h6>Location : {billData?.registers.customers[0].location}</h6> */}
                <h6>Payment Mode: {payMode}</h6>
              </CCardText>
            </CCardBody>
          </CCol>
          <CCol sm={6} className="text-end">
            <CCardBody>
              <CCardText className="text-end">
                <strong>{data.salonName}</strong>
                <h6>(+91) {data.mobile}</h6>
                <h6>{data.email}</h6>
                <h6>
                  {moment(billData?.appointmentDate).format("DD-MM-YYYY")}{"  "}
                  {billData.billedAt}
                </h6>
                <h6>GST - {salonData.GSTNumber}</h6>
                <h6 className="lh-sm">{salonData.salonAddress}</h6>
              </CCardText>
            </CCardBody>
          </CCol>
        </CRow>
        <CRow className="align-items-start mt-4">
          <CTable>
            <CTableHead>
              <CTableRow color="light">
                <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Service</CTableHeaderCell>
                <CTableHeaderCell scope="col">Staff</CTableHeaderCell>
                <CTableHeaderCell scope="col">Price (₹)</CTableHeaderCell>
                <CTableHeaderCell scope="col">Tax(%)</CTableHeaderCell>
                <CTableHeaderCell scope="col">Disc.(%)</CTableHeaderCell>
                <CTableHeaderCell scope="col">Total (₹)</CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {billData?.appointmentservices.map((services, index) => (
                <CTableRow>
                  <CTableDataCell>{index + 1}</CTableDataCell>
                  <CTableDataCell>
                    {services.service.serviceName}
                  </CTableDataCell>
                  <CTableDataCell>
                    {services.registers.firstName} {services.registers.lastName}
                  </CTableDataCell>

                  <CTableDataCell>
                    {services.service.specialPrice
                      ? services.service.specialPrice
                      : services.service.fullPrice}
                  </CTableDataCell>
                  <CTableDataCell>
                    {services.service.taxApplicable === null ? 0 : 18}
                  </CTableDataCell>
                  <CTableDataCell>
                    {services.discount === null
                      ? appointmentData[index].discountPercent
                      : services.discount}
                  </CTableDataCell>
                  <CTableDataCell>
                    {services.total === null
                      ? appointmentData[index].calculatedTotal
                      : services.total.toFixed(2)}
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>

            <CTableFoot>
              <CTableRow
                style={{ borderTop: "2px solid grey" }}
                className="pt-4"
              >
                <CTableDataCell rowSpan={4} colSpan={5}></CTableDataCell>
                <CTableDataCell>Total</CTableDataCell>
                <CTableDataCell>
                  {"\u20B9"} {totalAmount}
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>IGST / CGST</CTableDataCell>
                <CTableDataCell>₹ {totalTaxAmount?.toFixed(2)}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>Discount</CTableDataCell>
                <CTableDataCell>₹ {calculatedDiscount}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>Final</CTableDataCell>
                <CTableDataCell>₹ {finalAmount}</CTableDataCell>
              </CTableRow>
            </CTableFoot>
          </CTable>
        </CRow>
      </div>
    );
  }
);
export default AppointmentPrint;
