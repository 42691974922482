import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import navigation from "./_nav";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const logo = require("../assets/images/logo/logo.jpg");

const AppSidebar = () => {
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const token = userdata ? userdata.data.token : null;
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [clientLogo, setClientLogo] = useState(null);

  useEffect(() => {
    axios
      .get(`${baseURL}/profile/getProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        // console.log(data);
        setClientLogo(data.salonLogo ? filePathToUrl(data.salonLogo) : logo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function filePathToUrl(filePath) {
    const imageBaseUrl = baseURL;
    const imagePath = filePath
      .replace(/\\/g, "/")
      .split("/")
      .slice(1)
      .join("/");
    return `${imageBaseUrl}/static/${imagePath}`;
  }

  return (
    <CSidebar
      style={{ backgroundColor: "#424499" }}
      position="fixed"
      unfoldable={!unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage fluid src={clientLogo} alt="logo" className="brandLogo" />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      /> */}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
